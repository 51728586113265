import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-form"
export default class extends Controller {
  static targets = ["teamsData", "companySelect", "teamSelect"]

  connect() {
    this.allTeams = JSON.parse(this.teamsDataTarget.dataset.teams)
  }

  updateTeams() {
    const selectedCompanyId = this.companySelectTarget.value
    const filteredTeams = this.allTeams.filter(
      (team) => team.company_id == selectedCompanyId
    )

    this.teamSelectTarget.innerHTML = `<option value="">Select team</option>`
    filteredTeams.forEach((team) => {
      const option = document.createElement("option")
      option.value = team.id
      option.text = team.name
      this.teamSelectTarget.appendChild(option)
    })
  }

  // Triggered on company selection change
  companySelectTargetChanged() {
    this.updateTeams()
  }
}
