import { Controller } from "@hotwired/stimulus"
import { updateMap, getTravelTime} from "./application.js"

// Connects to data-controller="delivery-form"
export default class extends Controller {
  static targets = [
    "weight",
    "length",
    "vehicleType",
    "vehicleSelect",
    "deliveryAddressSection",
    "pickupAddressSection",
    "pickupAddressInput",
    "deliveryAddressInput",
    "hiddenTeamAddress",
    "clearServiceTypeButton",
    "hiddenServiceTypeInput",
    "serviceTypePickUpRadio",
    "serviceTypeExchangeRadio"
  ]

  connect() {
    this.vehicleProperties = JSON.parse(this.vehicleSelectTarget.dataset.vehicleProperties)
    this.toggleRequiredAddress()
    this.updateVehicleType()
    this.updateDimensionRequirements()
    this.previousServiceType = this.hasHiddenServiceTypeInputTarget ? this.hiddenServiceTypeInputTarget.value : null
  }

  saveAsDraft(event) {
    // Set the hidden field value to true
    document.getElementById("save_as_draft_field").value = true;
  }

  updateVehicleType() {
    const weight = parseFloat(this.weightTarget?.value) || 0
    const length = parseFloat(this.lengthTarget?.value) || 0

    // Find the appropriate vehicle type where both weight and length are within limits
    let selectedVehicle = Object.entries(this.vehicleProperties).find(([vehicleType, vehicle]) =>
      weight <= vehicle.max_weight && length <= vehicle.max_length
    )[0]

    if(!selectedVehicle) return;

    // Update the radio button selection for the vehicle type
    const selectedRadio = this.vehicleTypeTargets.find(input => input.value === selectedVehicle)
    if (selectedRadio) {
      selectedRadio.checked = true
      selectedRadio.dispatchEvent(new Event("click")) // Trigger events associated with manual click
    }
  }

  updateDimensionRequirements() {
    const selectedVehicleType = this.vehicleTypeTargets.find(input => input.checked)?.value
    if (selectedVehicleType === 's-van' || selectedVehicleType === 'm-van') {
      this.weightTarget.required = true
      this.lengthTarget.required = true
    } else {
      this.weightTarget.required = false
      this.lengthTarget.required = false
    }
  }

  enablePickUpMode() {
    this.previousServiceType = "pick_up"
    this.deliveryAddressSectionTarget.classList.add("d-none")
    this.pickupAddressSectionTarget.classList.remove("d-none")
    if(this.deliveryAddressInputTarget.value !== "") {
      this.pickupAddressInputTarget.value = this.deliveryAddressInputTarget.value
    }
    this.pickupAddressInputTarget.required = true
    this.deliveryAddressInputTarget.required = false
    this.toggleClearServiceTypeButton()
    updateMap()
    getTravelTime()
  }

  disablePickUpMode() {
    this.pickupAddressSectionTarget.classList.add("d-none")
    this.deliveryAddressSectionTarget.classList.remove("d-none")
    if(this.pickupAddressInputTarget.value !== "") {
      this.deliveryAddressInputTarget.value = this.pickupAddressInputTarget.value
    }
    this.pickupAddressInputTarget.required = false
    this.deliveryAddressInputTarget.required = true
    updateMap()
    getTravelTime()
  }

  enableExchangeMode() {
    if (this.previousServiceType === "pick_up") {
      this.disablePickUpMode();
    }

    this.previousServiceType = "exchange"; // Update state
    this.toggleClearServiceTypeButton()
    updateMap()
    getTravelTime()
  }

  toggleRequiredAddress() {
    if(!this.hasServiceTypePickUpRadioTarget || !this.hasPickupAddressInputTarget || !this.hasDeliveryAddressInputTarget) return

    if (this.serviceTypePickUpRadioTarget.checked) {
      this.pickupAddressInputTarget.required = true
      this.deliveryAddressInputTarget.required = false
    } else {
      this.pickupAddressInputTarget.required = false
      this.deliveryAddressInputTarget.required = true
    }
  }

  clearServiceType() {
    if (this.serviceTypePickUpRadioTarget.checked) {
      this.serviceTypePickUpRadioTarget.checked = false
      this.disablePickUpMode()
    } else if (this.serviceTypeExchangeRadioTarget.checked) {
      this.serviceTypeExchangeRadioTarget.checked = false
    }
    if (this.hiddenServiceTypeInputTarget) {
      this.hiddenServiceTypeInputTarget.value = '';
    }
    this.toggleClearServiceTypeButton()
  }

  toggleClearServiceTypeButton() {
    const selectedRadio = document.querySelector('input[name="delivery[service_type]"]:checked');
    if (selectedRadio) {
      this.clearServiceTypeButtonTarget.classList.remove("d-none");
    } else {
      this.clearServiceTypeButtonTarget.classList.add("d-none");
    }
  }
}
